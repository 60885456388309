export default (d, s, id, jsSrc, cb) => {
    const fjs = d.getElementsByTagName(s)[0]
    const js = d.createElement(s)
    js.id = id
    js.src = jsSrc
    if (fjs && fjs.parentNode) {
      fjs.parentNode.insertBefore(js, fjs)
    } else {
      d.head.appendChild(js)
    }
    js.onload = cb
  }


import React, { useState, useEffect } from "react"
import { useGoogleLogin } from "./services/auth"

export default ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if(!authenticated) {
      useGoogleLogin({
        onSuccess: () => {
          setAuthenticated(true);
        },
        onFailure: (err) => {
          console.log('error', err);
          setAuthenticated(false);
        }
      });
    }
  });

  if(authenticated) {
    return (
      <div>
        {children}
      </div>
    );
  } else {
    const imageList = [
      'https://images.unsplash.com/photo-1552083974-186346191183?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3300&q=80',
      'https://images.unsplash.com/photo-1541701494587-cb58502866ab?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3300&q=80',
      'https://images.unsplash.com/photo-1523821741446-edb2b68bb7a0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3300&q=80',
      'https://images.unsplash.com/photo-1496715976403-7e36dc43f17b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2848&q=80'
    ];

    const pickedIndex = Math.floor((Math.random() * imageList.length));

    return (
        <div className="login-layout" style={{backgroundImage: `url('${imageList[pickedIndex]}')`}}>
          <div className="login-content">
            <div className="spinner" /> Authenticating ...
          </div>
      </div>
    );
  }
}

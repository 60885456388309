import React, { useEffect } from 'react';
import loadScript from './load-script';
import './auth.css';

let GoogleAuth;

const authParams = {
  apiKey: null,
  discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
  clientId: '710368237557-rag3n2s82lu75b8c3hv53en3da31faek.apps.googleusercontent.com',
  scope: 'https://www.googleapis.com/auth/drive.metadata.readonly',
};

export const isBrowser = () => typeof window !== "undefined";
export const getUser = () => {
  return isBrowser() && window.localStorage.getItem("gatsbyUser") && window.localStorage.getItem('gatsbyUser') !== "undefined"
    ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
    : {};
}

const setUser = (user) =>
  window.localStorage.setItem("gatsbyUser", JSON.stringify(user.tc));

const removeUser = (user) =>
  window.localStorage.setItem("gatsbyUser", null);

export const isLoggedIn = () => {
  const user = getUser();
  return !!user && user.expires_at >= new Date();
};

export const logout = (callback) => {
  setUser({});
  callback()
};

export const useGoogleLogin = ({
  onSuccess,
  onFailure,
  }) => {
    loadScript(document, 'script', 'google-login', 'https://apis.google.com/js/api.js', () => {
      window.gapi.load('client', () => {
        window.gapi.client.init(authParams).then(function () {
          GoogleAuth = window.gapi.auth2.getAuthInstance();
          GoogleAuth.isSignedIn.listen(updateSignInStatus);

          if (GoogleAuth.isSignedIn.get()) {
            const user = GoogleAuth.currentUser.get();
            setUser(user);
            onSuccess();
          } else {
            GoogleAuth.signIn();
          }
        }).catch(onFailure);
      })
    })

    function updateSignInStatus(isSignedIn) {
      if(isSignedIn) {
        setUser(GoogleAuth.currentUser.get());
        onSuccess();
      } else {
        onFailure();
      }
    }

  return null;
};

export const useGoogleLogOut = ({
  onSuccess,
  onFailure,
  }) => {
    useEffect(() => {
      loadScript(document, 'script', 'google-login', 'https://apis.google.com/js/api.js', () => {
        window.gapi.load('client', () => {
          window.gapi.client.init(authParams).then(function () {
            GoogleAuth = window.gapi.auth2.getAuthInstance();
            GoogleAuth.signOut();
            removeUser();
            onSuccess();
          }).catch(onFailure);
        })
      })
    }, []);

  return null;
};
